<template>
  <section class="invoice">
    <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
      <div class="content-wrapper">
        <div class="page-header">
        <h3 class="page-title">
            Bon de commande
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <li class="breadcrumb-item">Mes Commandes</li>
            <li class="breadcrumb-item active" aria-current="page">Bon de commande</li>
            </ol>
        </nav>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card px-2">
                    <div class="card-body">
                        <div class="container-fluid">
                        <h3 class="text-right my-5"> <p>Référence :</p> MEB-{{ulid}}</h3>
                        <hr> </div>
                        <div class="container-fluid d-flex justify-content-between">
                        <div class="col-lg-3 pl-0">
                            <p class="mt-5 mb-2">
                            <b>Star Admin</b>
                            </p>
                            <p>Mon échappée Belle,
                            <br>64 avenue Saint Exupéry,
                            <br>81600 GAILLAC.</p>
                        </div>
                        <div class="col-lg-3 pr-0">
                            <p class="mt-5 mb-2 text-right">
                            <b>Facturé à</b>
                            </p>
                            <p class="text-right" v-if="orders.deliveryinfos">{{orders.deliveryinfos.name}}
                            <br> {{orders.deliveryinfos.line1}},
                            <br><span v-if="orders.deliveryinfos.line2">{{orders.deliveryinfos.line2}}</span>,
                            <br> {{orders.deliveryinfos.city}},
                            <br> {{orders.deliveryinfos.country}},
                            <br> {{orders.deliveryinfos.state}},
                            <br> {{orders.deliveryinfos.postal_code}}
                            </p>
                        </div>
                        </div>
                        <div class="container-fluid d-flex justify-content-between">
                        <div class="col-lg-3 pl-0">
                            <p class="mb-0 mt-5">Fait le: {{formatStringDate(orders.dateorder)}}</p>
                            <p>Pour le : {{formatStringDate(orders.datedelivery)}}</p>
                        </div>
                        </div>
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-if="orders.purchases && orders.purchases[0].service != null">
                            <!-- tableau réservation -->
                            <div class="table-responsive w-100">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Date</th>
                                    <th class="text-left">Durée</th>
                                    <th class="text-left">Débute à</th>
                                    <th class="text-left">Se termine à</th>
                                    <th class="text-right">Prix</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                    <td class="text-left">{{purchaseId+1}}</td>
                                    <td class="text-left">{{purchase.service.name}}</td>
                                    <td class="text-left">{{today(purchase.datestart)}}</td>
                                    <td class="text-left">{{calculTimes(purchase.service.duration)}}</td>
                                    <td class="text-left">{{toTime(purchase.datestart)}}</td>
                                    <td class="text-left">{{toTime(purchase.dateend)}}</td>
                                    <td class="text-right"> ${{purchase.service.price}} </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- tableau d'achat -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-else-if="orders.purchases && orders.purchases[0].product != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Prix unitaire</th>
                                    <th class="text-left">Quantité achetée</th>
                                    <th class="text-left">Prix total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                    <td class="text-left">{{purchaseId + 1}}</td>
                                    <td class="text-left">{{purchase.product.name}}</td>
                                    <td class="text-left">{{purchase.product.price * 1.2}}€</td>
                                    <td class="text-left">{{purchase.quantity}}</td>
                                    <td class="text-left">{{purchase.quantity * (purchase.product.price * 1.2) }}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <!-- tableau de bon de d'achat (vouchers) -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-else-if="orders.purchases && orders.purchases[0].voucher != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                        <th>#</th>
                                        <th>Envoyeur</th>
                                        <th class="text-left">Destinataire</th>
                                        <th class="text-left">Bon d'achat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                        <td class="text-left">{{purchaseId + 1}}</td>
                                        <td class="text-left">{{sender}}</td>
                                        <td class="text-left">{{receiver}}</td>
                                        <td class="text-left">{{purchase.voucher.price}}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else>La génération de la facture a rencontré une erreur</div>
                        <div class="container-fluid mt-5 w-100">
                        <h4 class="text-right mb-5">Total : ${{roundNumber(total)}}</h4>
                        <hr> </div>
                        <div class="container-fluid w-100 fluid-btn d-flex justify-content-end flex-sm-column flex-md-row">
                            <button class="btn btn-outline-primary float-left mt-4 ml-2"  @click="DeleteOrder()">
                                <i class="fa fa-trash"></i> Annuler
                            </button>
                            <button class="btn btn-primary float-right mt-4 ml-2" @click="print()">
                                <i class="mdi mdi-printer mr-1"></i>Imprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </section>
</template>

<script>
import axios from 'axios'
import pageLoader from '../components/pageLoader.vue';
import { priceMixin } from './mixins/price';

export default {
  name: 'ordersProductDetails',
  mixins:[priceMixin],
  components:{
      pageLoader
  },
  data () {
      return {
          ulid: '',
          orders: '',
          loadershow:true,
          purchases: []

      }
  },
  computed:{
      total: function(){
          var tota = 0;
          this.purchases.forEach(element => {
              if(element.service){
                  tota = tota + element.service.price
              }
              if(element.product){
                  tota = tota + (element.product.price * 1.2) * element.quantity
              }
              if(element.voucher){
                  tota = tota + element.voucher.price * element.quantity
              }
          }); 
          return tota
      },
      receiver(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.receiver.firstname +' '+ this.orders.purchases[0].voucher.receiver.lastname
          }else{
              return 'hi'
          }
      },
      sender(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.sender.firstname +' '+ this.orders.purchases[0].voucher.sender.lastname
          }else{
              return 'hi'
          }
      }
  },
  methods: {
      getOrdersDetails(ulid){
          axios.get(`myorders/${ulid}`,
          {
              headers: {
                  'X-AUTH-TOKEN': localStorage.getItem('token')
              }
          })
          .then(resGetOrdersDetails => {
              this.orders=resGetOrdersDetails.data.result
              this.purchases = this.orders.purchases
              this.loadershow = false
          })
          .catch(errOrdersDetails => console.log(errOrdersDetails))
      },
      print(){
          window.print()
      },
      calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
      toTime(time){
          return time.substr(11, 5)
      },
      today(time){
          return time.substr(0, 10)
      },
      formatStringDate(myDate){
          if (typeof myDate != "string" ) {
              // some thing else
              return ""
          }
          return myDate.substring(8,10)+'-'+ myDate.substring(5,7)+'-'+myDate.substring(0,4)+' '+myDate.substring(10,13)+':'+myDate.substring(14,16)
          
      },
    DeleteOrder(){
    this.$swal({
        title: 'Désirez-vous annuler votre réservation?',
        showCancelButton: true,
        confirmButtonColor: '#0b0b0b',
        cancelButtonColor: '#fff',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
    })
    .then((result) => {
        if (result.value) {
        this.loadershow = true;
        axios.delete('myorders/'+this.ulid)
            .then(()=>{
            this.$router.go(-1)
            })
            .catch()
        }else{
            this.loadershow=false
        }
    })
      }
  },
  mounted () {
      this.getOrdersDetails(this.ulid)
  },
  created () {
      this.ulid= this.$route.params.ulid
  }
}
</script>
<style scoped>
@media screen and (max-width: 992px) {
    .fluid-btn button{
        width:100% !important;
    }
}
</style>